import { type IconProps, Icon } from './icon.js';

export function Forward30(props: Omit<IconProps, 'children'>) {
  return (
    <Icon aria-label="Forward 30" {...props}>
      <svg viewBox="0 0 24 24">
        <path d="M19.4213 13.7093c-.5336 0-.9714.3836-1.0459.8916-.5123 3.4937-4.0237 6.0544-7.9195 5.1421-2.40143-.5599-4.17305-2.3534-4.68533-4.6964-.86457-4.012 2.2839-7.5576 6.26493-7.5576v2.8924c0 .4666.5763.6946.9073.3629l4.0451-3.92916c.2134-.20734.2134-.52872 0-.73607l-4.0451-3.92914c-.331-.32138-.9073-.0933-.9073.37321v2.89244c-5.27242 0-9.43477 4.64452-8.36747 9.95242.64022 3.2242 3.09505 5.702 6.39307 6.4173 5.1551 1.1196 9.7657-2.2808 10.4275-6.9149.0959-.6117-.4271-1.1611-1.0673-1.1611Z" />
        <path d="M10.3621 16.0903c-.03.0168-.0623.0318-.0983.0449-.0463.0169-.0958.0276-.1482.0339-.0282.0034-.0571.0055-.0871.0066l-.05285.001c-.09615 0-.18136-.0104-.26682-.0415-.05472-.0199-.10058-.0398-.14332-.065-.02397-.0143-.0469-.0303-.07009-.0491-.02267-.0182-.04247-.0365-.06019-.0556-.03257-.0353-.05785-.0736-.07869-.1206-.03179-.0726-.05316-.1451-.05316-.2281h-.90731c0 .2177.04274.4043.1282.5702.08521.1659.20273.2903.35204.394.14956.1036.30955.1866.49091.2384.18162.0519.37366.0726.5657.0726.22408 0 .43778-.0311.64048-.0829.2027-.0519.3627-.1452.5123-.2489.1493-.1036.2561-.2488.3416-.4043.0852-.1555.1279-.3421.1279-.5494 0-.2385-.0641-.4562-.192-.6324-.1282-.1763-.3203-.311-.5764-.4044.0508-.0246.1014-.0492.1498-.0761.0534-.0296.1043-.062.1491-.1001.0362-.0307.0703-.0614.1019-.0929.043-.0429.0807-.0873.1115-.1352l.0286-.0446.0245-.0393c.0206-.0344.0402-.0693.0571-.1054.0107-.0227.0203-.046.0287-.0698.0318-.0933.0424-.1867.0424-.28 0-.2073-.0424-.3835-.1172-.5494s-.1814-.2903-.3203-.394c-.1386-.1036-.2988-.1866-.4909-.2384-.192-.0518-.395-.0829-.62977-.0829-.20272 0-.40544.0311-.57638.0829-.17067.0518-.34134.1348-.46954.2384-.12795.1037-.24546.2281-.32025.3836-.07452.1555-.11725.311-.11725.4976h.90704c0-.0725.02137-.1451.05342-.2073.03205-.0622.07478-.114.1282-.1555.05342-.0415.11726-.0726.19204-.1037.03075-.0128.06149-.0203.09302-.0248.04508-.0063.09146-.0063.14175-.0063.03127 0 .06072.0009.08862.0029.0672.005.1253.0166.1782.0386.0748.0311.1389.0622.192.114.0535.0519.0855.1141.1176.1763.032.0622.0427.1451.0427.228 0 .1867-.0534.3318-.1709.4458-.1173.1141-.2773.1659-.51232.1659h-.45886v.6842h.48023c.11755 0 .21365.0104.30955.0415.0962.0311.1709.0622.2348.114.0641.0519.1175.1244.1495.2074.0321.0829.0535.1866.0535.3006 0 .0933-.0107.1763-.0428.2488-.0232.0524-.0573.0885-.0948.128-.0143.0152-.0292.031-.0441.0483-.037.0299-.074.0564-.1151.0795Z" />
        <path d="M14.0098 12.3408c.2348 0 .4378.0311.6298.1037s.3523.1866.4909.3421c.1389.1555.2455.3629.3202.5909.0748.2281.1173.5184.1173.8501v.7672c0 .3318-.0318.6117-.1066.8501-.0748.2385-.1813.4354-.3095.5909-.1282.1555-.2882.2696-.4803.3422-.192.0725-.395.1036-.6298.1036-.2347 0-.4375-.0311-.6295-.1036-.1923-.0726-.3523-.1867-.4912-.3422-.1386-.1555-.2454-.3628-.3199-.5909-.0748-.2281-.1176-.5183-.1176-.8501v-.7672c0-.3317.0428-.6116.1069-.8501.0638-.2384.1813-.4354.3093-.5909.1282-.1555.2882-.2695.4805-.3421.192-.0726.3947-.1037.6295-.1037Zm.5977 3.2657c.0321-.1348.0428-.3007.0428-.4977h.0107v-1.0056c0-.1404-.0055-.265-.0201-.3738-.006-.0438-.0136-.0851-.0227-.1238-.032-.1348-.0748-.2384-.1282-.3214-.0531-.0829-.1279-.1451-.2027-.1762-.0745-.0311-.1707-.0518-.2668-.0518-.0959 0-.1814.0207-.2668.0518-.0853.0311-.1387.0933-.2028.1762-.0641.083-.0961.1866-.1282.3214-.0318.1348-.0424.3007-.0424.4976v1.0056c0 .197.0106.3629.0424.4977.0321.1347.0748.2488.1282.3317s.1282.1452.2028.1763c.0747.0311.1709.0518.2668.0518.0961 0 .1816-.0207.2668-.0518.0855-.0311.1496-.0934.2027-.1763.0534-.0829.0855-.197.1175-.3317Z" />
      </svg>
    </Icon>
  );
}
