import { Flex } from '@iheartradio/web.accomplice/flex';
import { Link } from '@iheartradio/web.accomplice/link';
import {
  PlayerDescription,
  PlayerSubtitle,
  PlayerTitle,
} from '@iheartradio/web.accomplice/player';
import { isNull } from '@iheartradio/web.utilities';

import {
  buildAlbumUrl,
  buildArtistUrl,
  buildSongUrl,
} from '~app/utilities/urls';

import { useIsTouch } from '../../hooks/use-is-touch';
import { AddToPlaylist } from '../controls/add-to-playlist';
import { playback } from '../playback';

export function AlbumMetadata() {
  const metadata = playback.useMetadata();
  const { station } = playback.useState();
  const { isTouch } = useIsTouch();

  if (isNull(station) || isNull(metadata)) {
    return null;
  }

  const { artistId, description, id, subtitle, title, albumId } = metadata.data;

  return (
    <>
      <Flex direction="column" gap="$2" gridArea="text" minWidth="10rem">
        <PlayerSubtitle>
          {!isTouch && albumId && artistId ?
            <Link
              data-test="subtitle-link"
              href={buildAlbumUrl({
                artist: { id: artistId, name: title ?? '' },
                album: { id: albumId, name: subtitle ?? '' },
              })}
              underline="hover"
            >
              {subtitle}
            </Link>
          : subtitle}
        </PlayerSubtitle>
        <PlayerTitle>
          {!isTouch && id && artistId ?
            <Link
              data-test="title-link"
              href={buildSongUrl({
                artist: { id: artistId, name: title ?? '' },
                track: { id, name: description ?? '' },
              })}
              underline="hover"
            >
              {title}
            </Link>
          : title}
        </PlayerTitle>
        <PlayerDescription>
          {!isTouch && artistId ?
            <Link
              data-test="description-link"
              href={buildArtistUrl({
                id: artistId,
                name: title ?? '',
              })}
              underline="hover"
            >
              {description}
            </Link>
          : description}
        </PlayerDescription>
      </Flex>
      {Number(id) > 0 && <AddToPlaylist />}
    </>
  );
}
