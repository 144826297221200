import { Button } from '@iheartradio/web.accomplice/button';
import * as Icons from '@iheartradio/web.accomplice/icons';
import { PlayerTooltip } from '@iheartradio/web.accomplice/player';

import { playback } from '../playback';

export function Forward30({ isDisabled }: { isDisabled?: boolean }) {
  const player = playback.usePlayer();
  const { adBreak } = playback.useAds();

  return (
    <PlayerTooltip content="Forward 30 Seconds">
      <Button
        color={{ dark: 'white', light: 'gray' }}
        data-test="forward-30-player-button"
        isDisabled={adBreak || isDisabled}
        kind="tertiary"
        onPress={() => {
          player.fastForward(30);
        }}
        size="icon"
      >
        <Icons.Forward30 size={32} />
      </Button>
    </PlayerTooltip>
  );
}
