import { Box } from '@iheartradio/web.accomplice/box';
import { Link } from '@iheartradio/web.accomplice/link';
import { VideoPlayerContainer } from '@iheartradio/web.accomplice/player';
import { Text } from '@iheartradio/web.accomplice/text';
import { forwardRef, useEffect } from 'react';

import { useConfig } from '~app/contexts/config';

import { playback } from './playback';

export const VideoPlayer = forwardRef(function VideoPlayer(
  _props,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const config = useConfig();
  const { adBreak, type } = playback.useAds();
  const showPlayer = adBreak && type === 'video';

  const subscribeLink = `${config.urls.account}/subscribe`;

  useEffect(() => {
    const isMobile = navigator.userAgentData?.mobile ?? false;
    const body = document.querySelector('body');

    if (showPlayer && isMobile && body) {
      body.style.overflow = 'hidden';
    }

    return () => {
      if (isMobile) {
        body!.removeAttribute('style');
      }
    };
  }, [showPlayer]);

  return (
    <VideoPlayerContainer ref={ref}>
      <Box
        aspectRatio="16 / 9"
        display={showPlayer ? 'block' : 'none'}
        id="jw-player"
        overflow="visible"
      />
      <Box
        display={showPlayer ? 'flex' : 'none'}
        flexDirection="row"
        justifyContent="flex-end"
        marginBottom="-2rem"
        marginRight="4rem"
        marginTop="2rem"
        width="100%"
      >
        <Text as="div" kind={{ mobile: 'subtitle-4', xsmall: 'subtitle-5' }}>
          <Link
            color="secondary"
            href={subscribeLink}
            target="_blank"
            underline="hover"
          >
            Try Ad-free Experience
          </Link>
        </Text>
      </Box>
    </VideoPlayerContainer>
  );
});

VideoPlayer.displayName = 'VideoPlayer';
