import { type IconProps, Icon } from './icon.js';

export function ChevronUp(props: Omit<IconProps, 'children'>) {
  return (
    <Icon aria-label="Chevron Up" {...props}>
      <svg viewBox="0 0 24 24">
        <path d="m7.83893 15.217 4.16647-4.1664 4.1664 4.1664c.4188.4188 1.0953.4188 1.5141 0 .4188-.4188.4188-1.0953 0-1.5141L12.757 8.77405c-.4187-.41879-1.0953-.41879-1.514 0L6.31409 13.7029c-.41879.4188-.41879 1.0953 0 1.5141.4188.4081 1.10604.4188 1.52484 0Z" />
      </svg>
    </Icon>
  );
}
