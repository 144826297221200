import { lightDark, vars } from '@iheartradio/web.accomplice';
import { Box } from '@iheartradio/web.accomplice/box';
import { Flex } from '@iheartradio/web.accomplice/flex';
import {
  AdChoices,
  Facebook,
  Instagram,
  Threads,
  TikTok,
  XFormerlyKnownAsTwitter,
  YouTube,
} from '@iheartradio/web.accomplice/icons';
import { Separator } from '@iheartradio/web.accomplice/separator';
import { Text } from '@iheartradio/web.accomplice/text';

import { FooterLink } from './footer-link';
import { FooterSocial } from './footer-social';

function Copyright() {
  return (
    <Text
      color={lightDark(vars.color.gray450, vars.color.brandWhite)}
      kind="caption-4"
    >
      © {new Date().getFullYear()} iHeartMedia, Inc.
    </Text>
  );
}

export function Footer() {
  return (
    <Flex
      asChild
      data-test="footer"
      flexDirection="column"
      gap={vars.space[16]}
      paddingBottom={vars.space[32]}
      paddingTop={vars.space[40]}
      paddingX={{
        mobile: vars.space[16],
        large: vars.space[32],
      }}
    >
      <footer>
        <Separator
          backgroundColor={lightDark(vars.color.gray200, vars.color.gray500)}
        />
        <Flex
          alignItems={{
            mobile: 'flex-start',
            large: 'center',
          }}
          flexDirection={{
            mobile: 'column',
            large: 'row',
          }}
          gap={{
            mobile: vars.space[16],
            large: vars.space[4],
          }}
        >
          <Text
            color={lightDark(vars.color.gray450, vars.color.brandWhite)}
            kind="caption-4"
          >
            Follow iHeartRadio on:
          </Text>
          <Flex asChild gap={vars.space[4]} margin="0">
            <ul>
              <FooterSocial
                icon={Facebook}
                link="https://www.facebook.com/iheartradio"
                name="facebook"
              />
              <FooterSocial
                icon={XFormerlyKnownAsTwitter}
                link="https://x.com/iHeartRadio"
                name="x"
              />
              <FooterSocial
                icon={Instagram}
                link="https://www.instagram.com/iHeartRadio/"
                name="instagram"
              />
              <FooterSocial
                icon={Threads}
                link="https://www.threads.com/iHeartRadio/"
                name="threads"
              />
              <FooterSocial
                icon={TikTok}
                link="https://www.tiktok.com/@iheartradio?lang=en"
                name="tiktok"
              />
              <FooterSocial
                icon={YouTube}
                link="https://www.youtube.com/user/iHeartRadio"
                name="youtube"
              />
            </ul>
          </Flex>
        </Flex>
        <Flex
          alignItems="flex-start"
          flexDirection={{
            mobile: 'column',
            large: 'row',
          }}
          gap={{
            mobile: vars.space[16],
            large: vars.space[24],
          }}
        >
          <Box
            display={{
              mobile: 'none',
              large: 'block',
            }}
          >
            <Copyright />
          </Box>
          <Flex
            alignItems="center"
            asChild
            flexDirection="row"
            flexWrap="wrap"
            gap={{
              mobile: vars.space[16],
              large: vars.space[24],
            }}
            margin="0"
          >
            <ul>
              <FooterLink link="https://www.iheart.com/help">Help</FooterLink>
              <FooterLink link="https://www.iheart.com/privacy">
                Privacy Policy
              </FooterLink>
              <FooterLink link="https://www.iheart.com/terms">
                Terms of Use
              </FooterLink>
              <FooterLink link="https://privacy.iheart.com/">
                Your Privacy Choices
              </FooterLink>
              <FooterLink link="https://www.iheart.com/adchoices">
                <Flex gap={vars.space[4]}>
                  AdChoices
                  <AdChoices
                    fill={lightDark(vars.color.gray450, vars.color.brandWhite)}
                    size={16}
                  />
                </Flex>
              </FooterLink>
            </ul>
          </Flex>
          <Box
            display={{
              mobile: 'block',
              large: 'none',
            }}
          >
            <Copyright />
          </Box>
        </Flex>
      </footer>
    </Flex>
  );
}
