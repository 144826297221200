import { lightDark, vars } from '@iheartradio/web.accomplice';
import { Box } from '@iheartradio/web.accomplice/box';
import { Button } from '@iheartradio/web.accomplice/button';
import { Flex } from '@iheartradio/web.accomplice/flex';
import { Gear } from '@iheartradio/web.accomplice/icons';
import { $path } from 'remix-routes';

import { useRegGateEvent } from '~app/analytics/use-reg-gate';
import { useUser } from '~app/contexts/user';
import { REG_GATE_TRIGGER_TYPES, Routes } from '~app/utilities/constants';

export function SearchBar() {
  const { onAnalyticsRegGateOpen } = useRegGateEvent();
  const user = useUser();

  return (
    <Box
      backgroundColor={lightDark(vars.color.gray100, vars.color.brandBlack)}
      display={{ mobile: 'none', large: 'flex' }}
      justifyContent="flex-end"
      paddingX={vars.space[32]}
      paddingY={vars.space[8]}
      position="sticky"
      right="0"
      top="0"
      width="100%"
      zIndex={vars.zIndex[1]}
    >
      <Flex gap={vars.space[16]}>
        {user.isAnonymous ?
          <>
            <Button
              color="gray"
              data-test="sign-up"
              href={$path(Routes.SignUp)}
              kind="secondary"
              onPress={() => {
                onAnalyticsRegGateOpen(REG_GATE_TRIGGER_TYPES.SIGN_UP);
              }}
              size="large"
            >
              Sign Up
            </Button>
            <Button
              color="red"
              data-test="login"
              href={$path(Routes.Login, { action: 'auth' })}
              kind="primary"
              onPress={() => {
                onAnalyticsRegGateOpen(REG_GATE_TRIGGER_TYPES.LOG_IN);
              }}
              size="large"
            >
              Log In
            </Button>
          </>
        : <Button
            color="gray"
            data-test="settings"
            href={$path(Routes.SignUp)}
            kind="secondary"
            size="large"
            target="_blank"
          >
            <Gear /> Settings
          </Button>
        }
      </Flex>
    </Box>
  );
}
