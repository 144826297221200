import { Button } from '@iheartradio/web.accomplice/button';
import * as Icons from '@iheartradio/web.accomplice/icons';
import { PlayerTooltip } from '@iheartradio/web.accomplice/player';

import { playback } from '../playback';

export function Back15({ isDisabled }: { isDisabled?: boolean }) {
  const player = playback.usePlayer();
  const { adBreak } = playback.useAds();
  const time = playback.useTime();

  return (
    <PlayerTooltip content="Back 15 Seconds">
      <Button
        color={{ dark: 'white', light: 'gray' }}
        data-test="back-15-player-button"
        isDisabled={adBreak || isDisabled}
        kind="tertiary"
        onPress={() => {
          if (time.position <= 15) {
            player.seek(0);
          } else {
            player.rewind(15);
          }
        }}
        size="icon"
      >
        <Icons.Back15 size={32} />
      </Button>
    </PlayerTooltip>
  );
}
