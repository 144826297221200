import { Flex } from '@iheartradio/web.accomplice/flex';
import { Link } from '@iheartradio/web.accomplice/link';
import {
  PlayerDescription,
  PlayerSubtitle,
  PlayerTitle,
} from '@iheartradio/web.accomplice/player';
import { isNull } from '@iheartradio/web.utilities';

import { useIsTouch } from '~app/hooks/use-is-touch';
import {
  buildArtistTopSongsUrl,
  buildArtistUrl,
  buildSongUrl,
} from '~app/utilities/urls';

import { AddToPlaylist } from '../controls/add-to-playlist';
import { playback } from '../playback';

export function TopSongsMetadata() {
  const metadata = playback.useMetadata();
  const { station } = playback.useState();
  const { isTouch } = useIsTouch();

  if (isNull(station) || isNull(metadata)) {
    return null;
  }

  const { artistId, artistName, id, subtitle, title } = metadata.data;

  const artistUrl =
    artistId && artistName ?
      buildArtistUrl({ name: artistName, id: artistId })
    : undefined;

  return (
    <>
      <Flex direction="column" gap="$2" gridArea="text" minWidth="10rem">
        <PlayerSubtitle>
          {!isTouch && artistId ?
            <Link
              data-test="subtitle-link"
              href={buildArtistTopSongsUrl({
                id: artistId,
                name: artistName,
              })}
              underline="hover"
            >
              {subtitle}
            </Link>
          : subtitle}
        </PlayerSubtitle>
        <PlayerTitle>
          {!isTouch && id && artistId && artistName ?
            <Link
              data-test="title-link"
              href={buildSongUrl({
                artist: { id: artistId, name: artistName },
                track: { id, name: title ?? '' },
              })}
              underline="hover"
            >
              {title}
            </Link>
          : title}
        </PlayerTitle>
        <PlayerDescription>
          {!isTouch && artistId && artistUrl ?
            <Link
              data-test="description-link"
              href={artistUrl}
              underline="hover"
            >
              {artistName}
            </Link>
          : artistName}
        </PlayerDescription>
      </Flex>
      {Number(id) > 0 && <AddToPlaylist />}
    </>
  );
}
