import { type IconProps, Icon } from './icon.js';

export function Threads(props: Omit<IconProps, 'children'>) {
  return (
    <Icon aria-label="Threads" {...props}>
      <svg viewBox="3 3 19 19">
        <path d="M16.05 11.34a6.08 6.08 0 0 0-.24-.1c-.13-2.56-1.53-4.03-3.88-4.05h-.03c-1.4 0-2.58.6-3.3 1.7l1.3.88a2.29 2.29 0 0 1 2.02-.99c.77 0 1.35.23 1.73.67.28.32.46.76.55 1.32a9.88 9.88 0 0 0-2.22-.1c-2.23.12-3.67 1.42-3.57 3.23.05.92.5 1.71 1.29 2.23.66.43 1.5.65 2.4.6a3.54 3.54 0 0 0 2.72-1.33 4.9 4.9 0 0 0 .92-2.43c.56.34.97.78 1.2 1.31.39.9.41 2.39-.8 3.6-1.06 1.06-2.34 1.52-4.26 1.53-2.14-.01-3.76-.7-4.81-2.04-.99-1.25-1.5-3.06-1.51-5.37.02-2.31.52-4.12 1.5-5.37 1.06-1.34 2.68-2.03 4.82-2.04 2.15.01 3.8.7 4.89 2.05a6.6 6.6 0 0 1 1.2 2.45l1.52-.4a8.2 8.2 0 0 0-1.52-3.07c-1.4-1.72-3.45-2.6-6.09-2.62-2.64.02-4.67.9-6.02 2.63C4.65 7.17 4.02 9.31 4 12c.02 2.69.65 4.83 1.86 6.37C7.2 20.1 9.24 20.98 11.87 21h.01c2.34-.02 4-.63 5.35-1.99a4.87 4.87 0 0 0 1.14-5.37 4.6 4.6 0 0 0-2.32-2.3Zm-4.04 3.8c-.98.06-2-.38-2.05-1.32-.03-.7.5-1.48 2.11-1.57a9.45 9.45 0 0 1 2.17.15c-.18 2.31-1.27 2.69-2.23 2.74Z" />
      </svg>
    </Icon>
  );
}
