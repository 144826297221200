import { type IconProps, Icon } from './icon.js';

export function Back15(props: Omit<IconProps, 'children'>) {
  return (
    <Icon aria-label="Back 15" {...props}>
      <svg viewBox="0 0 24 24">
        <path d="M11.96 5.411V2.52c0-.467-.577-.695-.907-.363L7.009 6.085a.503.503 0 0 0 0 .736l4.044 3.928c.33.32.906.093.906-.363V7.484c3.98 0 7.127 3.544 6.253 7.555-.502 2.353-2.465 4.25-4.876 4.736-3.81.778-7.202-1.761-7.714-5.192-.064-.497-.512-.88-1.046-.88-.64 0-1.152.549-1.067 1.17.662 4.55 5.122 7.918 10.168 6.965 3.329-.632 6.007-3.234 6.658-6.467 1.056-5.317-3.095-9.96-8.376-9.96Z" />
        <path d="M10.786 16.811h-.907v-3.378l-1.078.321v-.715l1.889-.653h.096v4.425Zm1.878-4.415-.267 2.25.715.165c.032-.031.064-.072.106-.093a.8.8 0 0 0 .064-.036.783.783 0 0 1 .064-.037.726.726 0 0 1 .16-.051c.054-.01.128-.021.214-.021a.98.98 0 0 1 .32.052c.085.03.16.082.224.155a.637.637 0 0 1 .139.249c.032.103.042.207.042.321 0 .114 0 .218-.032.321a1.196 1.196 0 0 1-.117.26.514.514 0 0 1-.192.176.59.59 0 0 1-.288.062.706.706 0 0 1-.448-.156c-.118-.093-.182-.238-.203-.425h-.896c0 .208.053.394.138.55.086.155.193.3.342.404.15.103.31.197.49.249.182.051.374.082.566.082.267 0 .49-.051.683-.124a1.332 1.332 0 0 0 .768-.788c.064-.176.096-.362.096-.56 0-.227-.042-.434-.096-.621a1.13 1.13 0 0 0-.267-.466 1.277 1.277 0 0 0-.437-.29 1.476 1.476 0 0 0-.8-.083c-.064.01-.128.03-.192.041a.57.57 0 0 0-.16.052.637.637 0 0 1-.053.022.355.355 0 0 0-.065.03l.118-.954h1.813v-.736h-2.55Z" />
      </svg>
    </Icon>
  );
}
