import { lightDark, vars } from '@iheartradio/web.accomplice';
import { Button } from '@iheartradio/web.accomplice/button';
import * as Icons from '@iheartradio/web.accomplice/icons';
import { PlayerTooltip } from '@iheartradio/web.accomplice/player';
import { Playback } from '@iheartradio/web.playback';
import { isNull } from '@iheartradio/web.utilities';

import { useUser } from '~app/contexts/user';
import { isPremiumUser } from '~app/utilities/user';

import { playback } from '../playback';

const canShuffle = {
  [Playback.StationType.Album]: true,
  [Playback.StationType.Artist]: false,
  [Playback.StationType.Favorites]: false,
  [Playback.StationType.Live]: false,
  [Playback.StationType.Scan]: false,
  [Playback.StationType.Playlist]: true,
  [Playback.StationType.PlaylistRadio]: false,
  [Playback.StationType.Podcast]: false,
  [Playback.StationType.TopSongs]: true,
} as const;

export function Shuffle() {
  const player = playback.usePlayer();
  const state = playback.useState();
  const isPremium = isPremiumUser(useUser());

  if (isNull(state.station) || !canShuffle[state.station.type]) {
    return null;
  }

  const isShuffle = isPremium ? state.shuffled : true;

  return (
    <PlayerTooltip content={isShuffle ? 'Shuffle off' : 'Shuffle'}>
      <Button
        color={{ light: 'gray', dark: 'white' }}
        css={{
          boxShadow: vars.shadow.elevation1,
        }}
        kind="tertiary"
        onPress={() => {
          player.setShuffle();
        }}
        size="icon"
      >
        {isShuffle ?
          <Icons.ShuffleActive
            color={lightDark(vars.color.blue500, vars.color.blue400)}
            size={32}
          />
        : <Icons.Shuffle size={32} />}
      </Button>
    </PlayerTooltip>
  );
}
