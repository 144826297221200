import { slideUpDesktop, slideUpMobile } from '@iheartradio/web.accomplice';
import { Box } from '@iheartradio/web.accomplice/box';
import {
  PlayerMetadataLayout,
  PlayerProgressProvider,
  PlayerRoot,
  PlayerSection,
} from '@iheartradio/web.accomplice/player';
import { addToast } from '@iheartradio/web.accomplice/toast';
import { Playback, PlayerErrorCode } from '@iheartradio/web.playback';
import { useNavigate, useNavigation } from '@remix-run/react';
import { useEffect, useReducer, useRef } from 'react';
import { isNonNullish, isNullish } from 'remeda';
import { ClientOnly } from 'remix-utils/client-only';
import { useHydrated } from 'remix-utils/use-hydrated';

import { AdsTargetingState, useTargetingReady } from '~app/contexts/ads';
import { useUser } from '~app/contexts/user';

import { Actions } from './actions';
import { ControlSet } from './control-set';
import { Time } from './controls/time';
import { Metadata } from './metadata/metadata';
import { playback } from './playback';
import { isPremiumStation, premiumStationFallback } from './playback-helpers';
import { VideoPlayer } from './video-player';

// This function is used to get profile page url with minimum information
// This function is taking care of playback types Artists, Live, Favorites, Playlist, Playlist Radio, Album and Top Songs
function profilePageUrl(station: Playback.Station) {
  let parentStation = '';
  let stationType: string | Playback.StationType = station.type;
  let stationId = station.id;

  if (station.type === Playback.StationType.Album) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    parentStation = `/${Playback.StationType.Artist}/${station.artistId}`;
  }

  if (
    station.type === Playback.StationType.PlaylistRadio ||
    station.type === Playback.StationType.Playlist
  ) {
    stationType = Playback.StationType.Playlist;
    // below is for the playlists as id format and in url it is different
    stationId = stationId.toString().replace('::', '-');
  }

  if (station.type === Playback.StationType.TopSongs) {
    parentStation = `/${Playback.StationType.Artist}/${stationId}`;
    stationId = station.seed ?? stationId;
    stationType = 'song'; // As url struture has 'song' not 'top-song'
  }

  return `${parentStation}/${stationType}/${stationId}`;
}

export function Player() {
  const navigation = useNavigation();

  const player = playback.usePlayer();
  const state = playback.useState();
  const lastError = playback.useError();
  const { station } = state;

  const { adBreak, type } = playback.useAds();
  const showVideoPlayer = adBreak && type === 'video';

  // const [isFullScreen, setIsFullScreen] = CompanionPlayer.useFullScreen();

  const loaded = useRef(false);
  const loading = useRef(false);
  const loadAttempts = useRef(0);

  const videoPlayerRef = useRef<HTMLDivElement>(null);
  const playerBarRef = useRef<HTMLDivElement>(null);

  // This is an ['official'](https://legacy.reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate) workaround for `forceUpdate`
  const [reload, forceRerender] = useReducer(x => x + 1, 0);

  const user = useUser();
  const sessionRef = useRef(user?.sessionId);
  const navigate = useNavigate();

  // If the user has logged out, we want to reload the player, in case what was loaded previously
  // cannot be played by a (now) anonymous user
  useEffect(() => {
    // If the session id stored in ref does not match the current user session
    if (
      sessionRef.current !== user?.sessionId &&
      isPremiumStation(station.type)
    ) {
      // then we use our `useReducer` call to force a re-render
      forceRerender();
      // set the loading flags to false, so that the effect below will not exit early
      loaded.current = false;
      loading.current = false;
    }
  }, [user?.sessionId, station.type]);

  const hydrated = useHydrated();
  // De-structuring the `load` method so that hook dependencies can be exhaustively provided below
  // This, along with using a `key` in `useFetcher` will give us the same fetcher load method each
  // time, so `useEffect` will pass referential equality check
  // const { load: recsLoad } = useFetcher<PlayerContentTypeRecommendationsLoader>(
  //   {
  //     key: `${station.id}`,
  //   },
  // );

  useEffect(() => {
    const [error] = lastError?.slice(-1) ?? [];
    // if we're still trying to load a premium fallback or the default station, don't pop an error
    // toast just yet;
    if (!loaded.current && loadAttempts.current < 2) return;
    if (
      isNullish(error) ||
      isNullish(error.message) ||
      ('code' in error &&
        (error.code === PlayerErrorCode.PlayAttemptFailed ||
          error.code === PlayerErrorCode.ApiError))
    ) {
      return;
    }

    addToast({
      kind: 'error',
      text: error.message,
    });
  }, [lastError]);

  // const [companion, setCompanion] = useState<JSX.Element | null>(null);
  // useEffect(() => {
  //   if (isNotBlank(current?.companions)) {
  //     const companionToRender =
  //       current?.companions?.find(companion => {
  //         return (
  //           companion.height === NAV_AD_SIZES[0][1] &&
  //           companion.width === NAV_AD_SIZES[0][0]
  //         );
  //       }) ?? null;
  //     if (companionToRender) {
  //       setCompanion(<CompanionAd companion={companionToRender} fullscreen />);
  //     } else {
  //       setCompanion(null);
  //     }
  //   } else {
  //     setCompanion(null);
  //   }
  // }, [current]);

  // useEffect(() => {
  //   setIsFullScreen(false);
  // }, [location, setIsFullScreen]);

  const checkReadyToLoad = useTargetingReady();

  useEffect(() => {
    if (loaded.current || loading.current) return;

    const doLoad = (station: Playback.Station) => {
      if (isNonNullish(station)) {
        loading.current = true;
        player
          .load({
            ...station,
            targeting: AdsTargetingState.get('targetingParams'),
          })
          .then(() => {
            loaded.current = true;
            loading.current = false;
            return null;
          })
          .catch(() => {
            loadAttempts.current += 1;
            loading.current = false;

            doLoad(premiumStationFallback(station, loadAttempts.current));
          });
      } else {
        loading.current = true;
        player
          .load({
            id: 1469,
            context: 0,
            type: Playback.StationType.Live,
            targeting: AdsTargetingState.get('targetingParams'),
          })
          .then(() => {
            loaded.current = true;
            loading.current = false;
            return null;
          })
          .catch(() => (loading.current = false));
      }
    };

    // If the AdsTargeting is ready, then go ahead an load
    if (checkReadyToLoad()) {
      doLoad(station);
    } else {
      // Otherwise, check every 100ms until AdsTargeting is ready
      (async function doCheck() {
        setTimeout(() => {
          if (checkReadyToLoad()) {
            doLoad(station);
          } else {
            doCheck();
          }
        }, 100);
      })();
    }
  }, [player, station, checkReadyToLoad, reload]); // include 'reload' in the deps array so that this effect will run if we need to reload the player

  useEffect(() => {
    const { current: videoPlayer } = videoPlayerRef;

    let observed = false;
    let observerInstantiated = false;

    const observer = new IntersectionObserver(
      entries => {
        const { top } = entries[0].boundingClientRect;
        const playerHeight =
          playerBarRef.current?.getBoundingClientRect().height ?? 0;
        const offset = top + playerHeight;

        if (videoPlayer && offset < 0 && observed) {
          videoPlayer.dataset.scrolled = '1';
        } else {
          observed = true;
        }
      },
      {
        rootMargin: '100% 0%',
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      },
    );
    const scrollTarget =
      document.querySelector('#scroll-target')?.nextElementSibling;

    if (scrollTarget && showVideoPlayer) {
      observerInstantiated = true;
      observer.observe(scrollTarget);
    }

    if (navigation.state === 'loading' && observerInstantiated && videoPlayer) {
      videoPlayer.dataset.scrolled = '1';
    }

    return () => {
      if (videoPlayer && !adBreak) {
        videoPlayer.dataset.scrolled = '0';
      }
      if (scrollTarget && observerInstantiated) {
        observer.unobserve(scrollTarget);
      }
    };
  }, [navigation.state, player, adBreak, showVideoPlayer]);

  useEffect(() => {
    if (!player.initialized && hydrated) {
      (async function doInitialize() {
        await player.initialize();
      })();
    }
  }, [player, hydrated]);

  const time = playback.useTime();
  const isLiveStation = station?.type === Playback.StationType.Live;
  const position = isLiveStation && !adBreak ? 0 : time.position;

  return (
    <Box
      alignSelf="end"
      animationDuration="350ms"
      animationFillMode="forwards"
      animationName={
        loaded.current ?
          { mobile: `${slideUpMobile}`, large: `${slideUpDesktop}` }
        : undefined
      }
      animationTimingFunction="ease-out"
      bottom="0"
      gridArea="player"
      height="min-content"
      position="fixed"
      ref={playerBarRef}
      transform="translateY(100%)"
      width="100%"
      zIndex={{ mobile: '$3', large: '$10' }}
    >
      <ClientOnly>
        {() => (
          <PlayerProgressProvider duration={time.duration} position={position}>
            <PlayerRoot
              // Need to revisit ticket - IHRWEB-21424
              onPress={() => {
                navigate(profilePageUrl(station));
              }}
            >
              <VideoPlayer ref={videoPlayerRef} />
              <PlayerMetadataLayout>
                <Metadata />
              </PlayerMetadataLayout>
              <PlayerSection
                alignItems="center"
                flexDirection="column"
                gap="$8"
                gridArea="controls"
                justifyContent="center"
                maxWidth={{
                  'container-large': '30rem',
                  'container-xlarge': '60rem',
                }}
              >
                <ControlSet />
                <Time />
              </PlayerSection>
              <Actions />
            </PlayerRoot>
          </PlayerProgressProvider>
        )}
      </ClientOnly>
    </Box>
  );
}
