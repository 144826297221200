import { type IconProps, Icon } from './icon.js';

export function SkipForward(props: Omit<IconProps, 'children'>) {
  return (
    <Icon aria-label="Skip Forward" {...props}>
      <svg viewBox="0 0 24 24">
        <path d="m7.58 16.89 5.77-4.07c.56-.4.56-1.24 0-1.63L7.58 7.11C6.91 6.65 6 7.12 6 7.93v8.14c0 .81.91 1.28 1.58.82ZM16 7v10c0 .55.45 1 1 1s1-.45 1-1V7c0-.55-.45-1-1-1s-1 .45-1 1Z" />
      </svg>
    </Icon>
  );
}
