import { useEffect } from 'react';

import { getRawAppsFlyerScript } from '~app/utilities/get-apps-flyer';

declare global {
  interface Window {
    AF_SMART_SCRIPT?: {
      generateOneLinkURL: (options: {
        oneLinkURL: string;
        afParameters: Record<string, any>;
      }) => { clickURL: string };
    };
  }
}

interface AppsFlyerOptions {
  isMobile?: boolean;
}

const generateLink = (deepLink = '') => {
  if (!window.AF_SMART_SCRIPT) {
    console.error('AppsFlyer script not loaded');
    return null;
  }
  const { clickURL: url } = window.AF_SMART_SCRIPT.generateOneLinkURL({
    oneLinkURL: 'https://iheart.onelink.me/Ff5B',

    // INFO: 'keys' array for each param will match against the query params of the incoming url and use that value
    // otherwise it will use default. The paramKey is the outgoing query param name that will be appended to the outgoing url.
    afParameters: {
      mediaSource: {
        // outgoing paramKey gets set as PID
        keys: ['utm_source'],
        defaultValue: 'Web-Listen',
      },
      // outgoing paramKey gets set as C
      campaign: {
        keys: ['utm_campaign'],
        defaultValue: 'Get The App (US)',
      },
      afCustom: [
        {
          keys: ['utm_dp'],
          paramKey: 'af_dp',
          defaultValue: `ihr://${deepLink}`,
        },
        { keys: ['utm_ss_ui'], paramKey: 'af_ss_ui', defaultValue: 'true' },
      ],
    },
  });

  return window.open(url, '_blank');
};

export function useAppsFlyer({ isMobile = false }: AppsFlyerOptions) {
  useEffect(() => {
    if (!isMobile) return;

    const script = document.createElement('script');

    script.innerHTML = `
      ${getRawAppsFlyerScript()}
    `;
    document.body.append(script);

    return () => script.remove();
  }, [isMobile]);

  if (!isMobile) return null;

  return {
    generateLink,
  };
}
