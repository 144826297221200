import { Button } from '@iheartradio/web.accomplice/button';
import { ChevronUp } from '@iheartradio/web.accomplice/icons';
import {
  PlayerSection,
  PlayerTooltip,
} from '@iheartradio/web.accomplice/player';

import { Menu } from './menu';
import { PlaybackSpeed } from './playback-speed';
import { Shuffle } from './shuffle';
import { Volume } from './volume';

export function Actions() {
  return (
    <PlayerSection
      gridArea="actions"
      isHidden={{ mobile: true, 'container-medium': false }}
      justifyContent="end"
      paddingRight="$16"
    >
      <PlaybackSpeed />
      <Shuffle />
      <Volume />
      <Menu />
      <PlayerTooltip content="Profile player coming soon!">
        <Button
          color={{ light: 'gray', dark: 'white' }}
          kind="tertiary"
          size="icon"
        >
          <ChevronUp size={32} />
        </Button>
      </PlayerTooltip>
    </PlayerSection>
  );
}
