import { lightDark, vars } from '@iheartradio/web.accomplice';
import { Box } from '@iheartradio/web.accomplice/box';
import { Grid } from '@iheartradio/web.accomplice/grid';
import { Loading } from '@iheartradio/web.accomplice/icons';
import { useNavigation } from '@remix-run/react';
import { useEffect } from 'react';

export function PageNavigationLoader() {
  const navigation = useNavigation();

  const isNavigating =
    Boolean(navigation.location) &&
    window.location.pathname !== navigation.location?.pathname;

  useEffect(() => {
    document.body.style.overflow = isNavigating ? 'hidden' : 'initial';
  }, [isNavigating]);

  return (
    <Box
      height="100vh"
      left="0"
      paddingBottom={{ mobile: '12.4rem', large: '8.8rem' }}
      paddingLeft={{ mobile: 0, large: '31.6rem' }}
      paddingTop={{ mobile: '4.8rem', large: 0 }}
      pointerEvents="none"
      position="fixed"
      top="0"
      width="100vw"
      zIndex="$2"
    >
      <Grid
        height="100%"
        placeContent="center"
        position="relative"
        width="100%"
      >
        <Box
          backgroundColor={
            isNavigating ?
              lightDark(vars.color.brandWhite, vars.color.brandBlack)
            : 'transparent'
          }
          bottom="0"
          height="100%"
          left="0"
          opacity={0.75}
          position="absolute"
          right="0"
          top="0"
          transition="background-color 200ms ease 300ms"
        />
        <Box
          height="100%"
          opacity={isNavigating ? 1 : 0}
          transition="opacity 200ms ease 300ms"
          width="100%"
        >
          <Loading
            color={lightDark(vars.color.gray300, vars.color.brandWhite)}
            size={72}
          />
        </Box>
      </Grid>
    </Box>
  );
}
