import { vars } from '@iheartradio/web.accomplice';
import { Box } from '@iheartradio/web.accomplice/box';
import { Button } from '@iheartradio/web.accomplice/button';
import { Flex } from '@iheartradio/web.accomplice/flex';
import {
  Gear,
  Home,
  Library,
  Microphone,
  Playlist,
  Radio,
  Search,
  UserSettings,
} from '@iheartradio/web.accomplice/icons';
import { Link } from '@iheartradio/web.accomplice/link';
import { LogotypeSecondary } from '@iheartradio/web.accomplice/logos';
import {
  Navigation,
  NavigationHeader,
  NavigationItem,
  NavigationList,
  NavigationLogo,
} from '@iheartradio/web.accomplice/navigation';
import { Spacer } from '@iheartradio/web.accomplice/spacer';
import { NavLink, useLocation, useMatches } from '@remix-run/react';
import { $path } from 'remix-routes';

import { NavAd } from '~app/ads/display/nav-ad';
import { useRegGateEvent } from '~app/analytics/use-reg-gate';
import { useIsMobile } from '~app/contexts/is-mobile';
import { useUser } from '~app/contexts/user';
import { useAppsFlyer } from '~app/hooks/use-apps-flyer';
import type { RouteHandle } from '~app/types';
import { REG_GATE_TRIGGER_TYPES, Routes } from '~app/utilities/constants';

export function Nav() {
  const appsFlyer = useAppsFlyer({ isMobile: useIsMobile() });
  const matches = useMatches() as { handle?: RouteHandle }[];
  const { onAnalyticsRegGateOpen } = useRegGateEvent();
  const { isAnonymous } = useUser();
  const { pathname } = useLocation();

  const navProfile = matches.some(match => match.handle?.navProfile);

  return (
    <Box asChild data-test="nav-box" zIndex={vars.zIndex[2]}>
      <Navigation
        data-has-button
        data-test="navigation-wrapper"
        key={`${isAnonymous ? 'anon' : 'auth'}-nav`}
      >
        <Box asChild>
          <NavigationHeader navProfile={navProfile}>
            <NavLink to={$path(Routes.Home)}>
              <LogotypeSecondary
                aria-label="iHeart Header Logotype"
                media="mobile"
                size={24}
              />
            </NavLink>
            <Box
              display={{ mobile: 'flex', large: 'none' }}
              gap={vars.space[8]}
            >
              {isAnonymous ?
                <Button
                  color="blue"
                  onPress={() => appsFlyer?.generateLink()}
                  size="small"
                >
                  Listen on the app
                </Button>
              : <Button
                  color="gray"
                  data-test="search"
                  href={$path(Routes.Search)}
                  kind="tertiary"
                  size="icon"
                >
                  <Search />
                </Button>
              }
              {isAnonymous ?
                <Button
                  color="gray"
                  data-test="login"
                  href={$path(Routes.Login, { action: 'auth' })}
                  kind="tertiary"
                  onPress={() => {
                    onAnalyticsRegGateOpen(REG_GATE_TRIGGER_TYPES.LOG_IN);
                  }}
                  size="icon"
                >
                  <UserSettings />
                </Button>
              : <Button
                  color="gray"
                  data-test="sign-up"
                  href={$path(Routes.SignUp)}
                  kind="tertiary"
                  size="icon"
                  target="_blank"
                >
                  <Gear />
                </Button>
              }
            </Box>
          </NavigationHeader>
        </Box>
        <Flex
          flexDirection="column"
          height="100%"
          justifyContent="flex-start"
          width="100%"
        >
          <NavigationLogo>
            <Link href={$path(Routes.Home)}>
              <LogotypeSecondary
                aria-label="iHeart Navigation Logotype"
                media="desktop"
                size={24}
              />
            </Link>
          </NavigationLogo>
          <Flex direction="column" height="100%" justifyContent="space-between">
            <Flex direction="column" justifyContent="flex-start">
              <NavigationList>
                <NavigationItem asChild isActive={pathname === Routes.Home}>
                  <NavLink data-test="home" end to={$path(Routes.Home)}>
                    <Home />
                    Home
                  </NavLink>
                </NavigationItem>
                <NavigationItem
                  asChild
                  isActive={pathname.startsWith(Routes.Search)}
                >
                  <NavLink data-test="search" end to={$path(Routes.Search)}>
                    <Search />
                    Search
                  </NavLink>
                </NavigationItem>
                <NavigationItem
                  asChild
                  isActive={pathname.startsWith(Routes.Radio)}
                >
                  <NavLink data-test="radio" id="radio" to={Routes.Radio}>
                    <Radio />
                    Radio
                  </NavLink>
                </NavigationItem>
                <NavigationItem
                  asChild
                  isActive={pathname.startsWith(Routes.Podcast.Directory)}
                >
                  <NavLink
                    data-test="podcasts"
                    id="podcasts"
                    to={Routes.Podcast.Directory}
                  >
                    <Microphone />
                    Podcasts
                  </NavLink>
                </NavigationItem>
                <NavigationItem
                  asChild
                  isActive={pathname.startsWith(Routes.Playlist.Directory)}
                >
                  <NavLink
                    data-test="playlists"
                    id="playlists"
                    to={Routes.Playlist.Directory}
                  >
                    <Playlist />
                    Playlists
                  </NavLink>
                </NavigationItem>
                <Box display={{ mobile: 'none', large: 'flex' }} width="100%">
                  <NavigationItem
                    asChild
                    isActive={pathname.startsWith(Routes.Library.Root)}
                  >
                    <NavLink
                      data-test="library"
                      id="library"
                      to={
                        isAnonymous ? Routes.Library.Root : Routes.Library.Live
                      }
                    >
                      <Library />
                      Your Library
                    </NavLink>
                  </NavigationItem>
                </Box>
                <Box
                  display={{ mobile: 'none', large: 'flex' }}
                  justifyContent="flex-start"
                  paddingX={vars.space[16]}
                  paddingY={vars.space[8]}
                  width="100%"
                >
                  <Button
                    color="blue"
                    onPress={() => appsFlyer?.generateLink()}
                    size="large"
                  >
                    Listen on the app
                  </Button>
                </Box>
              </NavigationList>
            </Flex>
            <Spacer bottom={vars.space[24]} left={vars.space[8]}>
              <NavAd />
            </Spacer>
          </Flex>
        </Flex>
      </Navigation>
    </Box>
  );
}
