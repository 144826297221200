import { lightDark, vars } from '@iheartradio/web.accomplice';
import { Button } from '@iheartradio/web.accomplice/button';
import { SkipForward } from '@iheartradio/web.accomplice/icons';
import { PlayerTooltip } from '@iheartradio/web.accomplice/player';
import { Text } from '@iheartradio/web.accomplice/text';
import { addToast } from '@iheartradio/web.accomplice/toast';
import { Playback } from '@iheartradio/web.playback';
import { useCallback } from 'react';
import { isNullish } from 'remeda';

import { useUser } from '~app/contexts/user';
import { isPremiumUser } from '~app/utilities/user';

import { playback } from '../../playback/playback';

/**
 * A user generally won't have this much, but this upper limit is to make sure we don't show the
 * Number.MAX_SAFE_INTEGER amount that Amp will return by default in some cases.
 */
const MAX_SKIPS = 100;

export function Next() {
  const player = playback.usePlayer();
  const { adBreak } = playback.useAds();
  const state = playback.useState();
  const isPremium = isPremiumUser(useUser());

  const next = useCallback(() => {
    if (
      !isPremium &&
      state.skips <= 0 &&
      state.station?.type !== Playback.StationType.Podcast
    ) {
      addToast({
        kind: 'info',
        title: "You've reached your skip limit.",
      });
    } else {
      player.next();
    }
  }, [isPremium, player, state.skips, state.station?.type]);

  const skips =
    (
      !isPremium &&
      state.skips < MAX_SKIPS &&
      state?.station?.type !== Playback.StationType.Podcast
    ) ?
      state.skips
    : undefined;

  const tooltipContent =
    isNullish(skips) ? 'Next' : (
      `Next (${skips} ${skips === 1 ? 'skip' : 'skips'} left)`
    );

  const isDisabled = adBreak;

  return (
    <>
      <PlayerTooltip content={tooltipContent}>
        <Button
          color={{ dark: 'white', light: 'gray' }}
          data-test="next-player-button"
          isDisabled={isDisabled}
          kind="tertiary"
          onPress={() => {
            next();
          }}
          size="icon"
        >
          <SkipForward size={32} />
          {isNullish(skips) ? null : (
            <Text
              css={{
                color:
                  isDisabled ?
                    lightDark(vars.color.gray300, vars.color.gray400)
                  : undefined,
                left: '50%',
                position: 'absolute',
                top: '0',
                transform: 'translateX(-50%)',
                zIndex: '$1',
              }}
              kind="caption-3"
            >
              {skips}
            </Text>
          )}
        </Button>
      </PlayerTooltip>
    </>
  );
}
